import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Navbar from "../../components/navbar";
import "./styles.css";
import RejectPopup from "../../components/RejectPopup";
import HiredPopup from "../../components/HiredPopup";
import UploadCandidatesModal from "../../components/UploadCandidates";
import InterviewWNoraPopup from "../../components/InterviewWNoraPopup";
import BulkInterviewWNoraPopup from "../../components/bulkInterviewWNoraPopup";
import DownloadIcon from "../../images/downloadCandidates.png";
import downloadCandidatesAsCSV from "../../components/downloadCandidatesAsCSV";
import { useMediaQuery } from '@mui/material';
import SourcedApplicants from "../../components/sourcedCandidates";
import ResumeScreenedCandidates from "../../components/resumeScreenedCandidates";
import NoraInterviewCandidates from "../../components/noraInterviewCandidates";
import TopCandidates from "../../components/topCandidates";
import RejectedCandidates from "../../components/rejectedCandidates";

function ApplicantListNew() {
  const { companyInterviewId } = useParams();
  const navigate = useNavigate();
  const location = useLocation(); // Access the passed data
  const { companyName, position, countCandidateToInterviews, jobDescription, interviewScript, companyId, subDomain, apiKey } = location.state || {};
  const [applicants, setApplicants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedApplicant, setSelectedApplicant] = useState(applicants.length > 0 ? applicants[0] : null);
  const [currentPage, setCurrentPage] = useState(1);
  const applicantsPerPage = 12;
  const [activeTab, setActiveTab] = useState('sourced');
  const [popupApplicant, setPopupApplicant] = useState(null);
  const [popupHiredApplicant, setPopupHiredApplicant] = useState(null);
  const [popupInterviewApplicant, setPopupInterviewApplicant] = useState(null);
  const [popupBulkInterviewApplicants, setPopupBulkInterviewApplicants] = useState(null);
  const [loadingApplicantId, setLoadingApplicantId] = useState(null);
  const [emailSentApplicantId, setEmailSentApplicantId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState("desc");
  const [isDownloading, setIsDownloading] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [isLoading, setIsLoading] = useState(false);
  const [atsId, setAtsId] = useState(null);
  const [isSyncing, setIsSyncing] = useState(false);

  const [selectedApplicants, setSelectedApplicants] = useState([]);

  const toggleApplicantSelection = (applicant) => {
    setSelectedApplicants(prevSelected => 
      prevSelected.includes(applicant)
        ? prevSelected.filter(a => a !== applicant)
        : [...prevSelected, applicant]
    );
  };

  const getCandidatesToInterviewEndpoint = process.env.REACT_APP_GET_CANDIDATES_TO_INTERVIEW;
  const getResumeScoreEndpoint = process.env.REACT_APP_GET_RESUME_SCORE;
  const markTopCandidateEndpoint = process.env.REACT_APP_MARK_TOP_CANDIDATE;
  const postMoveCandidateBackEndpoint = process.env.REACT_APP_SEND_MOVE_CANDIDATE_BACK;
  const bambooATSSyncEndpoint = process.env.REACT_APP_SYNC_BAMBOO_ATS_POSITION;

  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const response = await fetch(
          `${getCandidatesToInterviewEndpoint}?companyInterviewId=${companyInterviewId}`
        );
        const data = await response.json();
        
        // Handle the new response structure
        if (data.candidates) {
          // New structure with AtsId and Candidates
          setApplicants(data.candidates);
          
          setAtsId(data.atsId);
          
          // Set the first applicant as selected if there are applicants
          if (data.candidates.length > 0) {
            setSelectedApplicant(data.candidates[0]);
          }
        } else {
          // Fallback for old structure (direct array)
          setApplicants(data);
          
          // Set the first applicant as selected if there are applicants
          if (data.length > 0) {
            setSelectedApplicant(data[0]);
          }
        }
  
        setLoading(false);
      } catch (err) {
        console.error("Failed to fetch applicants:", err);
        setLoading(false);
      }
    };
  
    fetchApplicants();
  }, [companyInterviewId]);

  useEffect(() => {
    // Find the first appropriate applicant for the current tab
    let filteredApplicants = [];
    
    if (activeTab === 'sourced') {
      // Filter for "Submitted" applicants
      filteredApplicants = applicants.filter(applicant => getStatus(applicant) === "Submitted");
    } else if (activeTab === 'resume') {
      // Filter for "ResumeScreened" applicants and sort by score
      filteredApplicants = applicants
        .filter(applicant => getStatus(applicant) === "ResumeScreened")
        .sort((a, b) => b.resumeScore - a.resumeScore);
    } else if (activeTab === 'nora') {
      // Filter for interview candidates (both awaiting and interviewed)
      filteredApplicants = applicants.filter(
        applicant => getStatus(applicant) === "Awaiting" || getStatus(applicant) === "Interviewed"
      ).sort((a, b) => {
        const scoreA = a.interviewResults ? parseInt(JSON.parse(a.interviewResults).overallScore) || 0 : null;
        const scoreB = b.interviewResults ? parseInt(JSON.parse(b.interviewResults).overallScore) || 0 : null;
        
        if (scoreA !== null && scoreB !== null) return scoreB - scoreA;
        if (scoreA !== null && scoreB === null) return -1;
        if (scoreA === null && scoreB !== null) return 1;
        
        return new Date(b.appliedDate) - new Date(a.appliedDate);
      });
    } else if (activeTab === 'top') {
      // Filter for top candidates
      filteredApplicants = applicants.filter(applicant => getStatus(applicant) === "TopCandidate");
    } else if (activeTab === 'rejected') {
      // Filter for rejected candidates
      filteredApplicants = applicants.filter(applicant => getStatus(applicant) === "Rejected");
    }
    
    // Set the first applicant of the filtered list as selected
    if (filteredApplicants.length > 0) {
      setSelectedApplicant(filteredApplicants[0]);
    } else {
      setSelectedApplicant(null);
    }
  }, [activeTab, applicants]); 

  const handleSyncATS = async () => {
    setIsSyncing(true);
    
    try {
      const response = await fetch(bambooATSSyncEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          companyId: companyId,
          companyInterviewId: companyInterviewId,
          subdomain: subDomain,
          apiKey: apiKey,
          atsId: atsId
        })
      });
      
      if (!response.ok) {
        throw new Error('Failed to sync ATS');
      }
      
      const result = await response.json();
      
      // Show result in alert
      alert(result.message);
      
      // Refresh the page
      window.location.reload();
    } catch (error) {
      console.error('Error syncing ATS:', error);
      alert("Failed to sync ATS. Please try again.");
    } finally {
      setIsSyncing(false);
    }
  };

  const handlePushToResumeScreening = async (applicants) => {
    // Convert to array if not already an array
    const applicantArray = Array.isArray(applicants) ? applicants : [applicants];
    
    if (applicantArray.length === 0) {
      alert("No applicants selected.");
      return;
    }
    
    setIsLoading(true);
    try {
      // Prepare FormData with job description
      const formData = new FormData();
      formData.append("jobDescription", jobDescription);
      
      // Process each applicant
      for (let i = 0; i < applicantArray.length; i++) {
        const applicant = applicantArray[i];
        
        if (!applicant.resumeFileName) {
          console.error(`No resume file available for applicant: ${applicant.name}`);
          continue; // Skip this applicant
        }
        
        try {
          // Fetch the resume as a Blob
          const resumeResponse = await fetch(applicant.resumeFileName);
          if (!resumeResponse.ok) {
            console.error(`Failed to fetch resume for applicant: ${applicant.name}`);
            continue; // Skip this applicant
          }
          
          const resumeBlob = await resumeResponse.blob();
          
          // Add this candidate to the FormData
          // The index ensures each candidate has unique field names
          formData.append(`candidates[${i}].CandidateToInterviewId`, applicant.candidateToInterviewId);
          formData.append(`candidates[${i}].Resume`, resumeBlob, "resume.pdf");
        } catch (error) {
          console.error(`Error processing applicant ${applicant.name}:`, error);
          continue; // Skip this applicant
        }
      }
      
      // Only proceed if we have at least one valid candidate
      if (!formData.has("candidates[0].CandidateToInterviewId")) {
        throw new Error("No valid applicants to process.");
      }
      
      // Send to the backend
      const response = await fetch(getResumeScoreEndpoint, {
        method: "POST",
        body: formData
      });
      
      if (!response.ok) {
        throw new Error("Failed to get resume scores from server.");
      }
            
      alert("Resume screened successfully!");
      
      // Reload the page to show updated scores
      window.location.reload();
    } catch (error) {
      console.error("Error in resume screening:", error);
      alert(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePushToTopCandidate = async (applicant) => {
    try {
      if (!applicant || !applicant.candidateToInterviewId) {
        console.error("Invalid applicant or missing candidate ID.");
        return;
      }
  
      const formData = new FormData();
      formData.append("candidateToInterviewId", applicant.candidateToInterviewId);
  
      const response = await fetch(markTopCandidateEndpoint, {
        method: "POST",
        body: formData
      });
  
      if (!response.ok) {
        throw new Error("Failed to mark top candidate.");
      }
  
      alert("Top candidate marked successfully!");
      window.location.reload();
    } catch (error) {
      console.error("Error pushing to top candidate:", error);
      alert("Error: Could not mark top candidate.");
    }
  };

  const handleFileUpload = (files) => {
    console.log("Uploaded files:", files);
    // Add logic to process the uploaded files (e.g., API call to upload)
  };

  const handleDownloadCandidates = async () => {
    setIsDownloading(true); // Show loader and update button text
    try {
      await downloadCandidatesAsCSV(applicants); // Call the download function
    } catch (error) {
      console.error("Error downloading candidates:", error);
    } finally {
      setIsDownloading(false); // Reset loader state
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const sortApplicantsByDate = () => {
    const sortedApplicants = [...applicants].sort((a, b) => {
      const dateA = new Date(a.appliedDate);
      const dateB = new Date(b.appliedDate);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
  
    setApplicants(sortedApplicants);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc"); 
  };

  const getStatus = (applicant) => {
    if (applicant.rejected) {
      return "Rejected";
    } 
    if (applicant.hired) {
      return "Hired";
    }
    if (applicant.resumeScore && applicant.interviewWithNora === null) {
      return "ResumeScreened";
    }
    if (applicant.topCandidate) {
      return "TopCandidate";
    }
    if (applicant.interviewWithNora === null) {
      return "Submitted";
    }
    if (applicant.interviewWithNora === true && applicant.interviewResults === null) {
      return "Awaiting";
    }
    if (applicant.interviewWithNora === true && applicant.interviewResults !== null) {
      return "Interviewed";
    }
    return "N/A"; // Fallback for unexpected cases
  };

  const sortedApplicants = [...applicants]
  .filter(applicant => getStatus(applicant) === "Awaiting" || getStatus(applicant) === "Interviewed")
  .sort((a, b) => {
    const scoreA = a.interviewResults ? parseInt(JSON.parse(a.interviewResults).overallScore) || 0 : null;
    const scoreB = b.interviewResults ? parseInt(JSON.parse(b.interviewResults).overallScore) || 0 : null;

    // If both have scores, sort by score descending
    if (scoreA !== null && scoreB !== null) {
      return scoreB - scoreA;
    }

    // If one has a score and the other does not, prioritize the one with a score
    if (scoreA !== null && scoreB === null) return -1;
    if (scoreA === null && scoreB !== null) return 1;

    // If both are "Awaiting", sort by most recent appliedDate (descending)
    return new Date(b.appliedDate) - new Date(a.appliedDate);
  });

  const paginatedApplicants = sortedApplicants.slice(
    (currentPage - 1) * applicantsPerPage,
    currentPage * applicantsPerPage
  );

  const handleMoveBackCandidate = async () => {
    if (!selectedApplicant) {
      alert("Please select a candidate to move back!");
      return;
    }
  
    try {
      const response = await fetch(postMoveCandidateBackEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          candidateToInterviewId: selectedApplicant.candidateToInterviewId
        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to move candidate back');
      }
  
      const result = await response.json();
      alert("Candidate successfully moved back!");
      window.location.reload();
    } catch (error) {
      console.error('Error moving candidate back:', error);
      alert("Failed to move candidate back. Please try again.");
    }
  };

  return (
    <>
      <div>
      <Navbar showSidebar={false} />
      <div className="dashboard-container">
        <article className="glass-container">
            <button className="back-button-applicantList" onClick={() => navigate(-1)}>
          &lt; Back
        </button>
          <div className="flex-row-container-header2">
            <h1 className="title">{position}</h1>

            {!isMobile && ( 
              <div className="button-container">
                <button
                  className="download-candidates-button"
                  onClick={handleDownloadCandidates}
                  disabled={isDownloading}>
                  {isDownloading ? (
                      <>
                        <span className="loader"></span> Downloading Candidates...
                      </>
                    ) : (
                      <>
                        Download Candidates
                        <img src={DownloadIcon} alt="Download Icon" />
                      </>
                    )}
                </button>

                {atsId && (
                  <button
                    className="download-candidates-button"
                    onClick={handleSyncATS}
                    disabled={isSyncing}>
                    {isSyncing ? (
                      <>
                        <span className="loader"></span> Syncing ATS...
                      </>
                    ) : (
                      "Sync ATS"
                    )}
                  </button>
                )}

                <button
                  className="dashboard-add-job-button4"
                  onClick={() => setIsModalOpen(true)}>
                  + Upload Candidate
                </button>
              </div>
            )}

          </div>

          <h3 className="sub-title">{countCandidateToInterviews} Applicants </h3>
          <div className="kanban-dashboard-internship-title-container2">
          {!isMobile ? (
            <>
              <button 
                className={`kanban-dashboard-tab ${activeTab === 'sourced' ? 'active' : ''}`} 
                style={{ marginLeft: "10px" }} 
                onClick={() => {
                  setSelectedApplicant(null); // Clear selection first
                  setCurrentPage(1); // Reset to first page
                  setActiveTab('sourced');
                }}
              >
                Sourced
              </button>

              <button 
                className={`kanban-dashboard-tab ${activeTab === 'resume' ? 'active' : ''}`} 
                onClick={() => {
                  setSelectedApplicant(null); // Clear selection first
                  setCurrentPage(1); // Reset to first page
                  setActiveTab('resume');
                }}
              >
                Resume Screening
              </button>
            </>
          ) : (
            <button 
              className="kanban-dashboard-tab" 
              style={{ marginLeft: "10px", cursor: "pointer" }} 
              onClick={sortApplicantsByDate}
            >
              Date & Name {sortOrder === "asc" ? "↑" : "↓"}
            </button>
          )}

          <button 
            className={`kanban-dashboard-tab ${activeTab === 'nora' ? 'active' : ''}`} 
            onClick={() => {
              setSelectedApplicant(null); // Clear selection first
              setCurrentPage(1); // Reset to first page
              setActiveTab('nora');
            }}
          >
            Interview with Nora
          </button>

          <button 
            className={`kanban-dashboard-tab ${activeTab === 'top' ? 'active' : ''}`} 
            onClick={() => {
              setSelectedApplicant(null); // Clear selection first
              setCurrentPage(1); // Reset to first page
              setActiveTab('top');
            }}
          >
            Top Candidates
          </button>

          {!isMobile && (
            <button 
              className={`kanban-dashboard-tab ${activeTab === 'rejected' ? 'active' : ''}`} 
              onClick={() => {
                setSelectedApplicant(null); // Clear selection first
                setCurrentPage(1); // Reset to first page
                setActiveTab('rejected');
              }}
            >
              Rejected
            </button>
          )}
        </div>

          <div style={{ display: 'flex', marginTop: '10px' }}>
            {activeTab === "sourced" ? (
              <SourcedApplicants
                applicants={applicants}
                selectedApplicant={selectedApplicant}
                setSelectedApplicant={setSelectedApplicant}
                selectedApplicants={selectedApplicants}
                setSelectedApplicants={setSelectedApplicants}
                toggleApplicantSelection={toggleApplicantSelection}
                sortApplicantsByDate={sortApplicantsByDate}
                sortOrder={sortOrder}
                handlePushToResumeScreening={handlePushToResumeScreening}
                setPopupInterviewApplicant={setPopupInterviewApplicant}
                setPopupBulkInterviewApplicant={setPopupBulkInterviewApplicants}
                setPopupApplicant={setPopupApplicant}
                getStatus={getStatus}
                isLoading={isLoading}
                currentPage={currentPage}
                applicantsPerPage={applicantsPerPage}
                setCurrentPage={setCurrentPage}
              />
            ) : activeTab === "resume" ? (
              <ResumeScreenedCandidates
                applicants={applicants}
                selectedApplicant={selectedApplicant}
                setSelectedApplicant={setSelectedApplicant}
                selectedApplicants={selectedApplicants}
                setSelectedApplicants={setSelectedApplicants}
                toggleApplicantSelection={toggleApplicantSelection}
                sortApplicantsByDate={sortApplicantsByDate}
                sortOrder={sortOrder}
                handlePushToResumeScreening={handlePushToResumeScreening}
                setPopupApplicant={setPopupApplicant}
                setPopupInterviewApplicant={setPopupInterviewApplicant}
                setPopupBulkInterviewApplicant={setPopupBulkInterviewApplicants}
                getStatus={getStatus}
                currentPage={currentPage}
                applicantsPerPage={applicantsPerPage}
                setCurrentPage={setCurrentPage}
              />
            ) : activeTab === 'nora' ? (
              <NoraInterviewCandidates
                applicants={applicants}
                sortedApplicants={sortedApplicants}
                paginatedApplicants={paginatedApplicants}
                selectedApplicant={selectedApplicant}
                setSelectedApplicant={setSelectedApplicant}
                selectedApplicants={selectedApplicants}
                setSelectedApplicants={setSelectedApplicants}
                toggleApplicantSelection={toggleApplicantSelection}
                sortApplicantsByDate={sortApplicantsByDate}
                sortOrder={sortOrder}
                currentPage={currentPage}
                applicantsPerPage={applicantsPerPage}
                setCurrentPage={setCurrentPage}
                getStatus={getStatus}
                handlePushToTopCandidate={handlePushToTopCandidate}
                setPopupApplicant={setPopupApplicant}
              />
            ) : activeTab === 'top' ? (
              <TopCandidates
                applicants={applicants}
                selectedApplicant={selectedApplicant}
                setSelectedApplicant={setSelectedApplicant}
                selectedApplicants={selectedApplicants}
                setSelectedApplicants={setSelectedApplicants}
                toggleApplicantSelection={toggleApplicantSelection}
                sortApplicantsByDate={sortApplicantsByDate}
                sortOrder={sortOrder}
                handleMoveBackCandidate={handleMoveBackCandidate}
                setPopupHiredApplicant={setPopupHiredApplicant}
                setPopupApplicant={setPopupApplicant}
                getStatus={getStatus}
              />
            ) : activeTab === 'rejected' ? (
              <RejectedCandidates
                applicants={applicants}
                selectedApplicant={selectedApplicant}
                setSelectedApplicant={setSelectedApplicant}
                selectedApplicants={selectedApplicants}
                setSelectedApplicants={setSelectedApplicants}
                toggleApplicantSelection={toggleApplicantSelection}
                sortApplicantsByDate={sortApplicantsByDate}
                sortOrder={sortOrder}
                setPopupApplicant={setPopupApplicant}
                getStatus={getStatus}
              />
            ) : (
              <p>No candidates available.</p>
            )}
          </div>
        </article>
      </div>
    </div>

    {popupHiredApplicant && (
        <HiredPopup
            isPopupVisible={!!popupHiredApplicant} // Pass visibility state
            onClose={() => setPopupHiredApplicant(null)} // Close the popup
            candidateToInterviewId={popupHiredApplicant.candidateToInterviewId}
            candidateEmail={popupHiredApplicant.email}
            candidateName={popupHiredApplicant.name}
            position={position}
            companyName={companyName}
        />
    )}

    {popupBulkInterviewApplicants && (
      <BulkInterviewWNoraPopup
        isPopupVisible={!!popupBulkInterviewApplicants}
        onClose={() => setPopupBulkInterviewApplicants(null)}
        selectedCandidates={popupBulkInterviewApplicants}
        position={position}
        companyName={companyName}
        setLoadingApplicantId={setLoadingApplicantId}
        setApplicants={setApplicants}
        setEmailSentApplicantId={setEmailSentApplicantId}
        interviewScript={interviewScript} 
        jobDescription={jobDescription}
        companyInterviewId={companyInterviewId}
      />
    )}

    {popupInterviewApplicant && (
      <InterviewWNoraPopup
        isPopupVisible={!!popupInterviewApplicant} // Pass visibility state
        onClose={() => setPopupInterviewApplicant(null)} // Close the popup
        candidateToInterviewId={popupInterviewApplicant.candidateToInterviewId}
        candidateEmail={popupInterviewApplicant.email}
        candidateName={popupInterviewApplicant.name}
        interviewWithNora={popupInterviewApplicant.interviewWithNora}
        position={position}
        companyName={companyName}
        interviewURL={popupInterviewApplicant.interviewURL} // Pass interview URL dynamically
        setLoadingApplicantId={setLoadingApplicantId} // Pass state function
        setApplicants={setApplicants}               // Pass state function
        setEmailSentApplicantId={setEmailSentApplicantId}
        interviewScript={interviewScript} 
        jobDescription={jobDescription}
        companyInterviewId={companyInterviewId}
      />
    )}

    {popupApplicant && (
        <RejectPopup
            isPopupVisible={!!popupApplicant} // Pass visibility state
            onClose={() => setPopupApplicant(null)} // Close the popup
            candidateToInterviewId={popupApplicant.candidateToInterviewId}
            candidateEmail={popupApplicant.email}
            candidateName={popupApplicant.name}
            position={position}
            companyName={companyName}
        />
    )}

    <UploadCandidatesModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)} // Close modal
        onUpload={handleFileUpload} // Handle file upload
        companyInterviewId={companyInterviewId}
      />
    </>
  );
}

export default ApplicantListNew;